import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { FaMapMarkedAlt, FaEnvelope, FaPhoneAlt, FaWhatsapp, FaFacebook, FaInstagram, FaYoutube, FaLinkedin } from 'react-icons/fa'

import unicollegeFooterLogo from '../../images/logos/unicollege-footer-logo.png'

const Footer = ({ learningMethods, studyFields, socialMediaLinks, quickLinks, contactInfo }) => {
  const currentYear = new Date().getFullYear()

  return (
    <StyledFooter>
      <div className="container">
        <div className="content">
          <div>
            <img id='logo' src={unicollegeFooterLogo} alt="" />
            <ul id='contact-info-list'>
              <li>
                <FaMapMarkedAlt />
                <span dangerouslySetInnerHTML={{__html: contactInfo?.address?.replaceAll('\n', '<br>')}} />
              </li>
              <li>
                <FaEnvelope />
                <a href={`mailto:${contactInfo?.email}`}>{contactInfo?.email}</a>
              </li>
              <li>
                <FaPhoneAlt />
                <a href={`tel:${contactInfo?.telephoneNumber?.replaceAll(' ', '')}`}>{contactInfo?.telephoneNumber}</a>
              </li>
              <li>
                <FaWhatsapp />
                <a href={`https://wa.me/+27${contactInfo?.whatsappNumber?.replaceAll(' ', '')}`} target='_blank' rel='noreferrer'>{contactInfo?.whatsappNumber}</a>
              </li>
            </ul>
          </div>

          <div>
            <h3>Join our Community</h3>
            {socialMediaLinks?.facebook && socialMediaLinks?.displayFacebook && (
              <a href={socialMediaLinks.facebook} title='Facebook' aria-label='Facebook' target='_blank' rel='noreferrer'>
                <FaFacebook />
              </a>
            )}
            {socialMediaLinks?.instagram && socialMediaLinks?.displayInstagram && (
              <a href={socialMediaLinks.instagram} title='Instagram' aria-label='Instagram' target='_blank' rel='noreferrer'>
                <FaInstagram />
              </a>
            )}
            {socialMediaLinks?.youtube && socialMediaLinks?.displayYoutube && (
              <a href={socialMediaLinks.youtube} title='YouTube' aria-label='YouTube' target='_blank' rel='noreferrer'>
                <FaYoutube />
              </a>
            )}
            {socialMediaLinks?.linkedin && socialMediaLinks?.displayLinkedin && (
              <a href={socialMediaLinks.linkedin} title='LinkedIn' aria-label='LinkedIn' target='_blank' rel='noreferrer'>
                <FaLinkedin />
              </a>
            )}
          </div>

          <div>
            <h3>Study Fields</h3>
            <ul>
              {studyFields.map(({title, slug}) => (
                <li key={slug.current}><Link to={`/study-fields/${slug.current}/`}>{title}</Link></li>
              ))}
            </ul>
          </div>

          <div>
            <h3>Learning Methods</h3>
            <ul>
              {learningMethods.map(({title, slug}) => (
                <li key={slug.current}><Link to={`/learning-methods/${slug.current}/`}>{title}</Link></li>
              ))}
            </ul>
          </div>

          <div>
            <h3>Quick Links</h3>
            <ul>
              {quickLinks?.map(({title, slug, url}) => (
                slug?.current 
                ? <li key={slug.current}><Link to={`/${slug.current}/`}>{title}</Link></li>
                : <li key={slug.current}><a href={url}>{title}</a></li>
              ))}
            </ul>
          </div>
        </div>
        <p className="copyright">&copy; UniCollege {currentYear} | Designed by Handcrafted Brands & Developed by <a href='https://itgenie.co.za' target='_blank' rel='noreferrer'>IT Genie</a></p>
      </div>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  background-color: #3B3B3B;
  color: #f4f4f4;
  padding: 2.5rem 2rem;
  font-size: 0.9rem;

  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  h3 {
    color: inherit;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
  }
  
  p {
    color: inherit;
  }

  ul {
    color: inherit;
    margin-bottom: 1.5rem;
    list-style: none;

    li {
      /* margin-bottom: 0.4rem; */
      line-height: 1.5;
    }

    svg {
      font-size: 1.2em;
    }

    &#contact-info-list {
      margin-left: 35px;
      text-indent: -35px;

      li {
        margin-bottom: 1rem;
        line-height: initial;
      }
    }
  }

  a {
    text-decoration: none;

    &:hover {
      color: var(--primary-color);
    }
  }

  img#logo {
    width: 90%;
    object-fit: contain;
  }

  svg {
    font-size: 1.9em;
    margin-right: 1rem;
  }
  
   div.content {
    display: grid;
    grid-template-columns: repeat(5, 1fr); // Change to fixed 5 columns instead of auto-fit
    grid-gap: 2rem;
    margin-bottom: 2rem;
    max-width: 1200px;
    margin: 0 auto; // Center the content div
    
    & > *:not(:first-child) {
      margin-top: 0; // Remove the top margin to keep everything aligned
    }
  }

  .container {
    max-width: 1400px; // Increase container width to accommodate all columns
    margin: 0 auto;
    padding: 0 2rem;
  }

  @media (max-width: 1200px) {
    div.content {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); // Revert to responsive layout on smaller screens
      & > *:not(:first-child) {
        margin-top: 2.2rem;
      }
    }
  }

  @media (max-width: 480px) {
    padding: 2rem;

    div.content {
      & > div {
        margin-top: 0 !important;
      }
    }
  }
`

export default Footer
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Navbar from './Navbar'
import Footer from './Footer'
import WhatsAppBubble from '../WhatsappBubble'
// import Seo from '../Seo'

// new way to do the head componet as HEMLET is deprecated
export const Head = ({ data }) => (
  <>
    <html lang="en-ZA" />
    <title>{data?.sanitySiteSettings?.title || 'UniCollege West Rand'}</title>
  </>
)


const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`query LayoutQuery {
  allSanityLearningMethod(sort: {rank: ASC}) {
    nodes {
      title
      slug {
        current
      }
    }
  }
  allSanityStudyField(sort: {title: ASC}) {
    nodes {
      title
      slug {
        current
      }
    }
  }
  sanityCompanyInfo {
    address
    email
    telephoneNumber
    whatsappNumber
    socialMediaLinks {
      facebook
      displayFacebook
      instagram
      displayInstagram
      youtube
      displayYoutube
      linkedin
      displayLinkedin
    }
  }
  sanitySiteSettings {
    title
    quickLinks {
      title
      slug {
        current
      }
      url
    }
  }
}`)

  const allLearningMethods = data.allSanityLearningMethod.nodes
  const allStudyFields = data.allSanityStudyField.nodes
  // const { socialMediaLinks, ...contactInfo } = data.sanityCompanyInfo
  let socialMediaLinks = null;
  let contactInfo = null;
  if (data.sanityCompanyInfo) {
    socialMediaLinks = data.sanityCompanyInfo.socialMediaLinks
    contactInfo = {
      address: data.sanityCompanyInfo.address,
      email: data.sanityCompanyInfo.email,
      telephoneNumber: data.sanityCompanyInfo.telephoneNumber,
      whatsappNumber: data.sanityCompanyInfo.whatsappNumber
    }
  }
  const quickLinks = data?.sanitySiteSettings?.quickLinks

  return (
    <>
      <Navbar learningMethods={allLearningMethods} studyFields={allStudyFields} location={location} />
      {children}
      <Footer learningMethods={allLearningMethods} studyFields={allStudyFields} socialMediaLinks={socialMediaLinks} quickLinks={quickLinks} contactInfo={contactInfo} />
      {contactInfo?.whatsappNumber && (
        <WhatsAppBubble number={contactInfo.whatsappNumber} />
      )}
    </>
  )
}

export default Layout

